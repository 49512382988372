import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import CanIUse from '../components/CanIUse';
export const _frontmatter = {
  "date": "2017-04-26",
  "title": "What Mastering Flexbox Will Do For You",
  "description": "Reasons to invest in really learning CSS Flexbox",
  "promo": "flexbox",
  "color": "#ff0c63"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As I prepared my first Mastery Game - `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` - for final release, one of my readers emailed asking:`}</p>
    <blockquote>
      <p parentName="blockquote">{` What will I get out of mastering flexbox?`}</p>
    </blockquote>
    <p>{`I spend a lot of hours each week thinking about my UI developer audience: what they struggle with and how I can help. I see so much pain and frustration from smart people just trying to build great layouts. `}</p>
    <p>{`CSS has had a rough history with a ton of quirks. The good people on the CSS committee (w3c) `}<strong parentName="p">{`designed flexbox as the solution`}</strong>{` to a lot of these problems. Once you have flexbox down it's absolutely amazing. There's a reason why people love it. Here is what mastering flexbox will do for you personally:`}</p>
    <h2>{`Prototype Faster`}</h2>
    <p>{`When you know flexbox like the back of your hand, you can quickly and effortlessly whip up a ton of layout ideas that you want to prototype. You no longer have to be jealous of how easy it is for your designer who gets to just drag things around in Sketch. `}</p>
    <p>{`You can quickly see how your UI looks with three even columns, or two fixed side columns and a flexible center. You can see how it looks having a component's children dock to the top center, or to the right and left sides with even space between.`}</p>
    <p>{`Your imagination will no longer be hamstrung. Once you master flexbox you'll find it handles a lot of your layout needs and you can prototype new and creative layouts so much faster.`}</p>
    <h2>{`Center Anything`}</h2>
    <p>{`Centering in CSS amiright?! Centering is probably the least intuitive thing even experienced devs still have a hard time with. `}</p>
    <p>{`For single lines of text maybe you currently use the `}<em parentName="p">{`matching line-height`}</em>{` trick:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.center {
  height: 40px;
  line-height: 40px;
}
`}</code></pre>
    <p>{`But what does line-height have to do with centering? Who knows - that's just the hack we're used to.`}</p>
    <p>{`For components that only have a single child with a known width maybe you currently use the `}<em parentName="p">{`margin`}</em>{` trick:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.center {
  margin: 0 auto;
}
`}</code></pre>
    <p>{`Hey it works! ...in some cases anyway. But what does margin have to do with centering? And now you can't use the margin property when you actually need it... so instead you hack in some extra padding plus relative positioning to get it back where you wanted it and...`}</p>
    <p>{`Nope.`}</p>
    <p>{`Flexbox was `}<em parentName="p">{`made for this`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
`}</code></pre>
    <p>{`Boom. All the children elements are justified horizontally and aligned vertically so as to land perfectly in the center, every time. And you still have margin and padding available for spacing if you need. `}</p>
    <p>{`It even works if you have multiple children elements or `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/how-to-pin-element-to-center-of-parent/"
      }}>{`if the child elements are larger than the parent`}</a>{`.`}</p>
    <h2>{`Forget About Floats`}</h2>
    <p>{`We used to have to use floats to accomplish basic layouts. Floats come with a lot of complexity and limitations. You have a tight coupling between floated elements and their next sibling element that needs a "clearfix". Or you have to use the "overlow: visible" hack. But what if you actually need to hide or scroll the overflow? Well then you've got to add a new containing element just for that. Compared to flexbox, floats are just not worth it for what you get. It's like pasta in my opinion (I don't like pasta). If stuff doesn't taste good it should at least be healthy for you, right? If it's neither what's the point? `}</p>
    <p>{`Flexbox makes floats obsolete, with just one exception: that 90's effect of floating text around images that you'll probably (hopefully) never be asked to use again.`}</p>
    <h2>{`Understand Others' Code`}</h2>
    <p>{`Writing new code is more fun than maintaining legacy code, hands down. But if we're being realistic most of us spend more of our time working with legacy code than writing new code.`}</p>
    <p>{`Trying to modify or extend a layout you don't fully understand is the worst. You take your best guess and pray it doesn't break something else in the app. That's no way to live. Once you master Flexbox, anytime you run into existing flexbox in your project you'll be able to make perfect sense of it and make your changes quickly. No more nightmares of CSS Jira bugs.`}</p>
    <h2>{`Drop the Cheat Sheet Crutch`}</h2>
    <p>{`If you've got a flexbox reference site set to your browser's homepage you might have a problem :) Cheat sheets and references are just a crutch. They're nice when you're in a pinch and still have absolutely no idea what you're doing, but you're not doing yourself any favors with your dependency on them. `}</p>
    <p>{`It's true that flexbox has a lot of properties that seem confusing at first. Like `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/the-difference-between-width-and-flex-basis/"
      }}>{`when to use flex-basis vs width`}</a>{`. Or that the `}<inlineCode parentName="p">{`flex`}</inlineCode>{` shorthand really means `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{`, `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{`, and `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` all in one.`}</p>
    <p>{`Cheat sheets aren't designed to give you skills, they're designed to be there for you when you don't have the skills yet. Gain the skills though and you'll no longer need the crutch.`}</p>
    <p>{`My `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` Mastery Game explains these properties (and more) all in an unforgettable way, and eases you into `}<strong parentName="p">{`spaced repetition`}</strong>{` challenges so it all becomes second nature. `}</p>
    <h2>{`Build Responsive Apps`}</h2>
    <p>{`Flexbox is also great for building responsive layouts. Though arguably `}<a parentName="p" {...{
        "href": "/post/grid-for-responsive-layout/"
      }}>{`CSS Grid is a better fit`}</a>{`. With Flexbox, parts of the UI can grow and shrink based on the constraints of the device. Adapting to media queries you can change the entire flow of your UI from horizontal to vertical with a single `}<inlineCode parentName="p">{`flex-direction`}</inlineCode>{` property.`}</p>
    <p>{`Flexbox isn't just for the web these days. You can target native mobile, native desktop, and even Virtual Reality (using `}<a parentName="p" {...{
        "href": "https://facebook.github.io/react-vr/"
      }}>{`React VR`}</a>{`).`}</p>
    <h2>{`Single Layout for All Browsers`}</h2>
    <p>{`It's funny, often when talking to devs about Flexbox they bring up concerns about cross-browser issues that they heard someone encountered once. I've built a bunch of production projects in Flexbox now and haven't encountered a single issue. There are some rare bugs that exist, mainly in older versions of IE and older Safari. But for the most part you can build using flexbox and things just work™ everywhere. I don't mess with fallbacks or any of that craziness. Flexbox works great.`}</p>
    <CanIUse feature="flexbox" mdxType="CanIUse" />
    <h2>{`Be In Demand`}</h2>
    <p>{`People who can build great UIs that work well and are cleanly coded are in crazy high demand. In the Salt Lake Valley where I live companies simply can't hire enough frontend devs. The demand is huge, especially if you're good. `}</p>
    <p>{`If you've never considered yourself a CSS wizard you're in luck: by mastering flexbox now you're skipping a whole era of wacky tricks and quirks that no longer apply. You can be up to date - and highly marketable - in no time.`}</p>
    <h2>{`Have Confidence You're Doing it Right`}</h2>
    <p>{`Once you master Flexbox you'll no longer need to use a CSS framework to feel like you're doing a good job. That `}<strong parentName="p">{`confidence`}</strong>{` in your own ability to build something great `}<strong parentName="p">{`from scratch`}</strong>{` is incredibly rewarding. Not just because it saves you time, but because you can be super proud of what you create. `}</p>
    <p>{`Remember that feeling that first got you hooked on building UIs? It's time to get it back. I look forward to seeing you gain mastery over Flexbox and become a formidable UI developer yourself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      